.download-form {
  font-family: 'Times New Roman', Times, serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #000;
  background-color: #fdfdfd;
  padding-top: 150px;
}

.download-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.download-form h1 {
  text-align: center;
  margin-bottom: 10px;
}

.download-form .form-content {
  display: flex;
}

.download-form .left-side {
  flex: 1;
  margin-right: 20px;
  text-align: center;
}

.download-form .left-side .profile-picture img {
  max-width: 100%;
  height: auto;
  border: 5px solid #000;
  border-radius: 12px;
}

.download-form .right-side {
  flex: 3;
}

.download-form ol {
  list-style-type: decimal;
  padding-left: 20px;
}

.download-form li {
  margin: 10px 0;
  font-size: 18px;
}

.dotted-line {
  display: inline-block;
  width: calc(100% - 150px);
  border-bottom: 1px dotted #000;
  padding-bottom: 2px;
  margin-left: 10px;
}

.aadhar-card-image {
  display: block;
  margin: 20px auto;
  max-width: 100%;
}

.download-form button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

@media print {
  body * {
    visibility: hidden;
  }

  .download-form, .download-form * {
    visibility: visible;
  }

  .download-form {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding: 0;
    border: none;
    box-shadow: none;
  }

  .download-form button {
    display: none;
  }
}

.installments-section {
  margin-top: 20px;
}

.installments-section h3 {
  text-align: center;
  margin-bottom: 10px;
}

.installments-table {
  width: 100%;
  border-collapse: collapse;
}

.installments-table th,
.installments-table td {
  border: 1px solid #000;
  padding: 8px;
  text-align: center;
}
