.loan-summary {
  padding: 20px;
  font-family: Arial, sans-serif;
  margin-top: 100px;
  overflow-x: scroll;
}

.loan-summary h2 {
  text-align: center;
  margin-bottom: 20px;
}

.loan-summary table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
}

.loan-summary th, .loan-summary td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.loan-summary th {
  background-color: #f2f2f2;
}

.loan-summary tr:hover {
  background-color: #f5f5f5;
}

.status-pending {
  color: red;
}

.status-accepted {
  color: green;
}

button {
  padding: 8px 16px;
  margin-top: 10px;
  border: none;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
  margin: 5px;
}

button:hover {
  background-color: #45a049;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px;
  border-radius: 8px;
  text-align: center;
  position: relative;
  height: 600px;
  overflow-y: scroll;
}

.close-button {
  color: #aaa;
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 28px;
  font-weight: bold;
}

.close-button:hover,
.close-button:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-content table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.modal-content th, .modal-content td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.modal-content th {
  background-color: #f2f2f2;
}


/* Status colors */
.status-submitted {
  color: green;
}

.status-pending {
  color: black; /* Normal color */
}

.status-overdue {
  color: red;
}