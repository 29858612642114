/* General Styles */
.dashboard {
  padding: 20px;
  padding-top: 150px;
}

.loan-services {
  margin-top: 20px;
}

.service-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.card {
  background: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  width: calc(50% - 20px);
}

.card h4 {
  margin-top: 0;
}

.apply-for-loan-banner {
  background: #eaf4e9;
  border: 1px solid #c8e6c9;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
  margin-top: 20px;
}

.apply-for-loan-banner button {
  background: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.apply-for-loan-banner button:hover {
  background: #45a049;
}

.loan-requests {
  margin-top: 20px;
}

.loan-requests table {
  width: 100%;
  border-collapse: collapse;
}

.loan-requests th, .loan-requests td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.loan-requests .status-pending {
  color: red;
}

.loan-requests .status-accepted {
  color: green;
}

.notification {
  background: #ffe6e6;
  border: 1px solid #f5c6cb;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
}

.close-button {
  font-size: 1.5em;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.status-submitted {
  color: blue;
}

.status-pending {
  color: orange;
}

.status-overdue {
  color: red;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .card {
    width: 100%;
  }

  .modal-content {
    width: 90%;
  }

  .apply-for-loan-banner {
    padding: 10px;
  }

  .apply-for-loan-banner button {
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .dashboard {
    padding: 10px;
    padding-top: 100px;
  }

  .service-cards {
    flex-direction: column;
    align-items: center;
  }

  .card {
    width: 100%;
  }

  .loan-requests th, .loan-requests td {
    padding: 8px;
  }

  .notification {
    padding: 8px;
  }

  .close-button {
    font-size: 1.2em;
    top: 5px;
    right: 5px;
  }
}
