/* Overall Container Styling */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url('/public/images/image.png') no-repeat center center fixed;
    background-size: cover;
    font-family: 'Arial', sans-serif;
    color: #fff;
    padding-top: 120px;
    padding-bottom: 130px;
  }
  
  /* Heading Styling */
  .container h1 {
    font-size: 4.5rem;
    margin-bottom: 30px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
  }
  
  /* Tabs Styling */
  .tabs {
    display: flex;
    border-bottom: 2px solid #fff;
    margin-bottom: 20px;
  }
  
  .tabs button {
    background: none;
    border: none;
    color: #fff;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: color 0.3s, border-bottom 0.3s;
  }
  
  .tabs button.active {
    color: #f39c12;
    border-bottom: 2px solid #f39c12;
  }
  
  /* Form Styling */
  form {
    background: rgba(0, 0, 0, 0.5);
    padding: 30px;
    border-radius: 8px;
    width: 950px; /* Increased width */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5); /* Slightly larger shadow */
    transition: transform 0.3s;
    margin-bottom: 160px;
  }
  
  form:hover {
    transform: translateY(-5px);
  }
  
  /* Form Elements Styling */
  form div {
    margin-bottom: 20px; /* Increased spacing */
  }
  
  form label {
    display: block;
    margin-bottom: 8px; /* Increased margin */
    font-weight: bold;
  }
  
  form input {
    width: 100%;
    padding: 12px; /* Increased padding */
    border: none;
    border-radius: 5px;
    background: #fff;
    color: #333;
    font-size: 1.1rem; /* Slightly larger font size */
  }
  
  form button {
    width: 100%;
    padding: 12px; /* Increased padding */
    border: none;
    border-radius: 5px;
    background: #f39c12;
    color: #fff;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  form button:hover {
    background: #e67e22;
  }
  
  /* Message Styling */
  .message {
    margin-top: 20px;
    font-size: 1rem;
  }
  

  @media(max-width:800px){
    form {
        width: 300px;
    }

    .container{
        padding-top: 50px;
    }

    .container h1 {
        font-size: 2em;
        text-align: center;
    }
  }


  .loader {
    font-size: 1.5em;
    color: #007bff;
    text-align: center;
    margin: 20px 0;
}

.message {
    color: red;
    text-align: center;
    margin: 20px 0;
}